import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

const CTA = () => {
  return (
    <>
      {/* <!-- CTA Area --> */}
      <div className="cta-section pt-13 pt-lg-25 pb-13 pb-lg-25">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="9" xl="9">
              <div className="text-center">
                <h2 className="gr-text-4 mb-7">
                  The only tool you need to schedule your staff.
                  <br /> Start your free trial now!
                </h2>
                <p className="gr-text-11 mt-6">
                  No credit card needed, cancel anytime, 24/7 support.{" "}
                </p>
                <Link
                  onClick={() => {
                    if (typeof window.gtag_report_conversion === "function") {
                      window.gtag_report_conversion(
                        process.env.SIGNUP_URL,
                      );
                    }
                  }}
                  to={process.env.SIGNUP_URL}
                  target="__blank"
                  className="btn btn-primary text-white gr-hover-y px-lg-9 mt-8"
                >
                  Start scheduling free
                </Link>
                <p className=" mt-10">
                  <Link
                    to="/pricing"
                    className="btn-link with-icon gr-text-10 font-weight-bold"
                  >
                    Or see Pricing Plans{" "}
                    <i className="icon icon-tail-right font-weight-bold"></i>
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTA;
