import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/scheduling-software/assign-shifts.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-16 pb-13 pt-lg-21 pb-lg-22 " id="elAssignShifts">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" sm="10" lg="5" className="pr-lg-9">
              <div
                className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Assign shifts in seconds
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  <ul className="list-unstyled">
                    <li className="py-4">Schedule employees based on availability, skills, roles, and locations.  </li>
                    <li className="py-4">Edit and copy previous schedule templates for quick scheduling.  </li>
                    <li className="py-4">Clone and import shifts for creating repeated schedules.  </li>
                    <li className="py-4">Make updates in bulk for fast large-scale editing.  </li>
                  </ul>
                </p>
              </div>
            </Col>
            <Col sm="10" lg="6" className="offset-lg-1 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="fade-left"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Assign shifts in seconds" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
