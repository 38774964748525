import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/scheduling-software/monitor-employees-attendance-and-availability.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 ">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="10" lg="6" className="mt-10 mt-lg-0 order-2 order-lg-1">
              <div
                className="content-img img-group-1"
                data-aos="fade-right"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="Monitor attendance and availability" />
              </div>
            </Col>
            <Col xs="10" lg="5" className="offset-lg-1 pr-lg-9 order-1 order-lg-2">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Monitor attendance and availability
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  <ul className="list-unstyled">
                    <li className="py-4">See who's arrived, who's running late, and who's off for the day.</li>
                    <li className="py-4">Know who can work and when, then assign shifts accordingly.</li>
                  </ul>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
