import React from "react";
import SEO from "../../components/Layout/SEO";
import PageWrapper from "../../components/PageWrapper";

import Hero from "../../sections/camelo/scheduling-software/Hero";
import Content1 from "../../sections/camelo/scheduling-software/Content1";
import Content2 from "../../sections/camelo/scheduling-software/Content2";
import Content3 from "../../sections/camelo/scheduling-software/Content3";
import Content4 from "../../sections/camelo/scheduling-software/Content4";
import Content5 from "../../sections/camelo/scheduling-software/Content5";
import Content6 from "../../sections/camelo/scheduling-software/Content6";
import Content7 from "../../sections/camelo/scheduling-software/Content7";
import Content8 from "../../sections/camelo/scheduling-software/Content8";
import Features from "../../sections/camelo/scheduling-software/Features";
import Testimonial from "../../sections/camelo/scheduling-software/Testimonial";
import BottomTestimonial from "../../sections/camelo/scheduling-software/BottomTestimonial";
import Faq from "../../sections/camelo/scheduling-software/Faq";
import Fact from "../../sections/camelo/scheduling-software/Fact";
import CTA from "../../sections/camelo/scheduling-software/CTA";

const SchedulingSoftwarePage = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Employee Scheduling Software | Work Schedule Maker | Camelo"
          description="
            Simplify the scheduling process & create cost-optimized staff
            schedules in minutes with the Camelo intuitive employee scheduling
            software."
        ></SEO>
        <Hero />
        <Testimonial />
        {/* <Features /> */}
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <Content5 />
        <Content6 />
        <Content7 />
        <Content8 />
        <Fact />
        <CTA />
        <BottomTestimonial />
        <Faq />
      </PageWrapper>
    </>
  );
};

export default SchedulingSoftwarePage;
