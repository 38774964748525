import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgT1 from "../../../assets/image/customers/noah.png";
import imgT2 from "../../../assets/image/customers/emma.png";
import imgStars from "../../../assets/image/svg/five-stars-rating.svg";


const Testimonial = () => {
  return (
    <>
      {/* <!-- Testimonial Area --> */}
      <div
        className="testimonial-section pt-9 pt-lg-20 pb-lg-15 bg-default-11"
        data-aos="zoom-out-up"
        data-aos-duration="750"
        data-aos-delay="400"
      >
        <Container>
          <Row className="align-items-center justify-content-around">
            <Col lg="5" xl="4">
              <div className="testimonial-widget-1 text-center mb-13 mb-lg-0">
                <div className="reviewer-img d-flex justify-content-center mb-7 mb-lg-10">
                  <img className="img-fluid circle-lg" src={imgT1} alt="" />
                </div>
                <div className="review">
                  <div>
                    <img src={imgStars} style={{width: "5rem"}} />
                  </div>
                  <h3 className="gr-text-6 mb-6 mb-lg-8">
                    “You made it so simple”
                  </h3>
                  <p className="name gr-text-color gr-text-9 font-weight-bold mb-0">
                    Noah Valdez
                  </p>
                  <p className="job gr-text-11">Owner at Languid Lounge</p>
                </div>
              </div>
            </Col>
            <Col lg="1">
              <div className="separator-line d-none d-lg-block"></div>
            </Col>
            <Col lg="5" xl="4">
              <div className="testimonial-widget-1 text-center mb-13 mb-lg-0">
                <div className="reviewer-img d-flex justify-content-center mb-7 mb-lg-10">
                  <img className="img-fluid circle-lg" src={imgT2} alt="" />
                </div>
                <div className="review">
                  <div>
                    <img src={imgStars} style={{width: "5rem"}} />
                  </div>
                  <h3 className="gr-text-6 mb-6 mb-lg-8">“Simply the best”</h3>
                  <p className="name gr-text-color gr-text-9 font-weight-bold mb-0">
                    Emma Alanis
                  </p>
                  <p className="job gr-text-11">Owner at Alanis Greengrocers</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Testimonial;
