import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgT from "../../../assets/image/customers/saleh.png";

const Testimonial = () => {
  return (
    <div className="py-24 bg-default-11">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col xs="10">
            <div className="testimonial-wrapper">
              <div
                className="single-testimonial media py-6"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="media-body">
                  <h3 className="gr-text-4 mb-12 open-quote">
                    I save so much time on creating and managing work schedules. Camelo declutters my mind and I can now focus on what's more important.
                  </h3>
                  {/* <img src={imgStars} style={{width: "5rem", paddingBottom: "1rem"}} /> */}
                  <span className="name gr-text-11 gr-text-color-opacity d-flex">
                    <div className="media-icon mr-8">
                      <img className=" circle-sm-2" src={imgT} alt="" />
                    </div>
                    <div>
                      <strong>Glitter Dringenden Clinics</strong><br />Dr. Saleh M Rahman
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonial;
