import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/scheduling-software/notify-schedules-instantly.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-5 pt-lg-19 pb-lg-23 bg-default-1">
        <Container>
          <Row className="align-items-center justify-content-center" >
            <Col
              sm="10"
              lg="6"
              className="mb-10 mb-lg-0 order-2 order-lg-1"
            >
              <div
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
                className="content-img img-group-2 pr-xl-16"
              >
                <img className="img-fluid" src={imgC} alt="Notify schedule updates instantly" />
              </div>
            </Col>
            <Col
              xs="10"
              lg="5"
              xl="5"
              className="offset-lg-1 order-1 order-lg-2"
            >
              <h2 className="gr-text-4 mb-6">Notify schedule updates instantly</h2>
              <p className="gr-text-8 pr-4 mb-11">
                <ul className="list-unstyled">
                  <li className="py-4">New schedules and updates are sent to your team via desktop or mobile.</li>
                  <li className="py-4">Request a confirmation to make sure your staff have received the updates.</li>
                  <li className="py-4">Reminders alert staff of their shifts, tasks, and task instructions.</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
