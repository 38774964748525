import React from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgWhyUs from "../../../assets/image/scheduling-software/free-time-clocks-app.png";
import imgStars from "../../../assets/image/svg/five-stars-rating.svg";

const Fact = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pb-10 bg-hover-blue py-20 py-md-28 py-xl-20  pt-xl-28 position-relative paperwapper">
        <Container>
          <Row className="align-items-start justify-content-center">
            <Col lg="6" xs="10">
              <div className="content-text text-left mb-12 pr-xl-11">
                <h2 className="title gr-text-4 mb-8">
                  Why use our scheduling app?
                </h2>
                <p className="gr-text-8">
                  <div className="media single-widget my-8">
                    <div className="count circle-sm gr-bg-blue-opacity-1 mr-8 mt-3">
                      <i className="icon text-primary icon-check-simple"></i>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-8 lead">Spend more time and energy on the right things instead of mundane scheduling work</h3>
                    </div>
                  </div>
                  <div className="media single-widget my-8">
                    <div className="count circle-sm gr-bg-blue-opacity-1 mr-8 mt-3">
                      <i className="icon text-primary icon-check-simple"></i>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-8 lead">No one loses track of their work, both employers and employees</h3>
                    </div>
                  </div>
                  <div className="media single-widget my-8">
                    <div className="count circle-sm gr-bg-blue-opacity-1 mr-8 mt-3">
                      <i className="icon text-primary icon-check-simple"></i>
                    </div>
                    <div className="media-body">
                      <h3 className="gr-text-8 lead">Scheduling issues and sudden absences are no longer terrifying</h3>
                    </div>
                  </div>
                </p>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="content-img rounded-8">
                <img className="w-100 rounded-8" src={imgWhyUs} alt="Free employee scheduling software" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Fact;
