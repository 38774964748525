import React from "react";
import {Link} from "gatsby";
import {Container, Row, Col, Button} from "react-bootstrap";

import imgC from "../../../assets/image/scheduling-software/cross-platform-supported.svg";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-13 pt-lg-21 pb-lg-22 " id="elAssignShifts">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" lg="6" className="pr-lg-9">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8">
                  Manage schedules anywhere, on any devices
                </h2>
                <p className="gr-text-8 pr-4 mb-11">
                  <ul className="list-unstyled">
                    <li className="py-4">Create, update, and manage schedules wherever you are, from any devices. </li>
                    <li className="py-4">Camelo is available on iOS, Android, macOS, and Windows.</li>
                  </ul>
                </p>
              </div>
            </Col>
            <Col sm="10" lg="6" className="pl-12 mt-10 mt-lg-0">
              <div
                className="content-img img-group-1"
                data-aos="fade-left"
                data-aos-duration="750"
              >
                <img className="img-fluid" src={imgC} alt="On any devices" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
