import React from "react";

import {Container, Row, Col} from "react-bootstrap";

import imgC from "../../../assets/image/scheduling-software/get-rid-of-scheduling-issues.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-5 pt-lg-19 pb-lg-23 bg-default-1">
        <Container>
          <Row className="align-items-center justify-content-center ">
            <Col sm="10" lg="6" className="mb-10 mb-lg-0 order-2 order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-easing="ease-in-out-quart"
                data-aos-duration="750"
                className="content-img img-group-2"
              >
                <img className="img-fluid" src={imgC} alt="Get rid of scheduling issues with Camelo app" />
              </div>
            </Col>
            <Col xs="10" lg="6" xl="6" className="order-1 order-lg-2">
              <h2 className="gr-text-4 mb-6">Say goodbye to scheduling issues</h2>
              <p className="gr-text-8">
                Handle all common scheduling issues with Camelo. Get rid of unexpected headaches.
              </p>
              <p className="gr-text-8 pl-0 ml-0">
                <div className="d-flex align-items-start py-2">
                  <div className="gr-text-8 text-primary mr-6 mt-2 d-flex align-items-center">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <div>Eliminate under-staffing and over-staffing.</div>
                </div>
                <div className="d-flex align-items-start py-2">
                  <div className="gr-text-8 text-primary mr-6 mt-2 d-flex align-items-center">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <div>Send reminders to employees' phones so no one forgets their shifts.</div>
                </div>
                <div className="d-flex align-items-start py-2">
                  <div className="gr-text-8 text-primary mr-6 mt-2 d-flex align-items-center">
                    <i className="icon icon-check-simple"></i>
                  </div>
                  <div>Find replacements quickly for emergencies, sudden absences, and no-call, no-shows.</div>
                </div>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
